import React from 'react'

import TextGroup from '../Texts'

const ToolboxOne = ({ lang }) => {
  const isEn = lang === 'en';
  return (
    <section id="toolbox-one" className="content container">
      <TextGroup
        rootProps={{ className: 'align-center' }}
        title={{
          type: 'h1',
          text: isEn ? 'Toolbox' : 'As Nossas Ferramentas.',
        }}
        description={{
          text:
            isEn ? 'Our toolbox evolves with time.\nCurrently, these are our tools of choice.' : 'A nossa caixa de ferramentas evolui com o tempo.\nAtualmente, estas são as ferramentas principais com que trabalhamos.',
        }}
      />
    </section>
  )
}
export default ToolboxOne
