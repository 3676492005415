import React from 'react'

import Layout from '../components/layout'
import ToolboxOne from '../components/toolbox/ToolboxOne'
import ToolboxTwo from '../components/toolbox/ToolboxTwo'

const Services = props => (
  <Layout lang="en" title="Toolbox" meta={{description: 'Our toolbox evolves with time. Meet our current tools of choice.'}} rootProps={{ className: 'page toolbox' }}>
    <ToolboxOne lang='en' />
    <ToolboxTwo lang='en' />
  </Layout>
)

export default Services
