import React from 'react'
import _ from 'lodash'

import TextGroup, { WorkTypes } from '../Texts'

import tile1 from '../../assets/images/tiles/Frontend.svg'
import tile2 from '../../assets/images/tiles/Backend.svg'
import tile3 from '../../assets/images/tiles/Databases.svg'

// Front End
import angular_logo from '../../assets/images/tools/frontend/angular.svg';
import css3_logo from '../../assets/images/tools/frontend/css3.svg';
import html5_logo from '../../assets/images/tools/frontend/html5.svg';
import js_logo from '../../assets/images/tools/frontend/js.svg';
import react_logo from '../../assets/images/tools/frontend/react.svg';
import vue_logo from '../../assets/images/tools/frontend/vue.svg';

// Back End
import java_logo from '../../assets/images/tools/backend/java.svg';
import dotnet_logo from '../../assets/images/tools/backend/dotnet.svg';
import laravel_logo from '../../assets/images/tools/backend/laravel.svg';
import php_logo from '../../assets/images/tools/backend/php.svg';
import python_logo from '../../assets/images/tools/backend/python.svg';
import wordpress_logo from '../../assets/images/tools/backend/wordpress.svg';
import nodejs_logo from '../../assets/images/tools/backend/nodejs.svg';
import golang_logo from '../../assets/images/tools/backend/golang.svg';
import scala_logo from '../../assets/images/tools/backend/scala.svg';
import rust_logo from '../../assets/images/tools/backend/rust.svg';

// Database
import dynamodb_logo from '../../assets/images/tools/databases/dynamodb.svg';
import mongodb_logo from '../../assets/images/tools/databases/mongodb.svg';
import mysql_logo from '../../assets/images/tools/databases/mysql.svg';
import postgresql_logo from '../../assets/images/tools/databases/postgresql.svg';
import sqlserver_logo from '../../assets/images/tools/databases/sqlserver.svg';
import oracle_logo from '../../assets/images/tools/databases/oracle.svg';
import redis_logo from '../../assets/images/tools/databases/redis.svg';
import elasticsearch_logo from '../../assets/images/tools/databases/elasticsearch.svg';
import clickhouse_logo from '../../assets/images/tools/databases/clickhouse.svg';

// Cloud
import aws_logo from '../../assets/images/tools/cloud/aws.svg';
import azure_logo from '../../assets/images/tools/cloud/azure.svg';
import google_cloud_logo from '../../assets/images/tools/cloud/google_cloud.svg';
import gitlab_logo from '../../assets/images/tools/cloud/gitlab.svg';
import jenkins_logo from '../../assets/images/tools/cloud/jenkins.svg';
import github_logo from '../../assets/images/tools/cloud/github.svg';
import docker_logo from '../../assets/images/tools/cloud/docker.svg';
import azure_pipelines_logo from '../../assets/images/tools/cloud/azure_pipelines.svg';
import kubernetes_logo from '../../assets/images/tools/cloud/kubernetes.svg';

// Message Broker
import kafka_logo from '../../assets/images/tools/message-brokers/kafka.svg';
import rabbitmq_logo from '../../assets/images/tools/message-brokers/rabbitmq.svg';



const ToolboxTwo = ({ lang }) => {
  const isEn = lang === 'en';

  const toolBlocks = [
    {
      rootProps: { name: 'frontend-architecture', className: 'row', id: 'frontend-architecture' },
      textLeadImage: tile1,
      text: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: isEn ? 'Front-End Architecture' : 'Arquitetura de Front-End',
        },
        description: {
          text:
            isEn ? "We improve user experience through various technologies and frameworks founded in server-side rendering and caching systems, allowing for fast content display and minimal resource consumption." : "Aprimoramos a experiência do utilizador através de diversas tecnologias e frameworks, com alicerces em server-side rendering e sistemas de cache que permitem uma apresentação rápida de conteúdos com consumos mínimos de recursos.",
        },
      },
      technologies: {
        rootProps: { className: 'col-6 col-12-medium' },
        items: [
          {
            // toolType: ['SPA', 'PWA', 'Electron'],
            logos: [
              {
                src: angular_logo,
                title: 'Angular',
              },
              {
                src: css3_logo,
                title: 'CSS3',
              },
              {
                src: html5_logo,
                title: 'HTML5',
              },
              {
                src: js_logo,
                title: 'JavaScript',
              },
              {
                src: react_logo,
                title: 'React',
              },
              {
                src: vue_logo,
                title: 'Vue',
              }
            ]
          },
        ]
      }
    },
    {
      rootProps: { name: 'backend-architecture', className: 'row', id: 'backend-architecture' },
      textLeadImage: tile2,
      text: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: isEn ? 'Back-End Architecture' : 'Arquitetura de Back-End',
        },
        description: {
          text:
            isEn ? "We believe in administrative tools that are as intuitive as they are flexible, and as such, one of our goals is to provide each client with a tailor-made, expandable back-end." : "Acreditamos em ferramentas administrativas que sejam tão intuitivas como flexíveis, e como tal, um dos nossos objetivos é proporcionar a cada cliente um back-end expansível e feito à medida.",
        },
      },
      technologies: {
        rootProps: { className: 'col-6 col-12-medium' },
        items: [
          {
            // toolType: ['Queues'],
            logos: [
				{
				src: java_logo,
				title: 'Java',
				}, {
				src: dotnet_logo,
				title: '.NET',
				},
				{
				src: php_logo,
				title: 'PHP',
				},
				{
				src: python_logo,
				title: 'Python',
				},
				{
				src: laravel_logo,
				title: 'Laravel',
				},
				{
				src: wordpress_logo,
				title: 'WordPress',
				},
				{
				src: nodejs_logo,
				title: 'Node.js',
				},
				{
				src: golang_logo,
				title: 'GoLang',
				},
				{
				src: scala_logo,
				title: 'Scala',
				},
				{
				  src: rust_logo,
				  title: 'Rust',
				}
			]
          },
        ]
      }
    },
    {
      rootProps: { name: 'databases', className: 'row', id: 'databases' },
      textLeadImage: tile3,
      text: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: isEn ? 'Databases' : 'Bases de Dados',
        },
        description: {
          text:
            isEn ? "Using relevant and renowned technologies, we pick the database model that best suits your business or objective, from editing institutional contents to managing products, services, and sales." : "Utilizando tecnologias relevantes e de renome, escolhemos o modelo de base de dados que melhor se adequa ao seu negócio ou objetivo, desde a edição de conteúdos institucionais à gestão de produtos, serviços e vendas.",
        },
      },
      technologies: {
        rootProps: { className: 'col-6 col-12-medium' },
        items: [
          {
            // toolType: ['PostGreSQL'],
            logos:
              [
                {
                  src: dynamodb_logo,
                  title: 'DynamoDB',
                }, {
                  src: mongodb_logo,
                  title: 'MongoDB',
                },
                {
                  src: mysql_logo,
                  title: 'MySQL',
                },
                {
                  src: postgresql_logo,
                  title: 'PostgreSQL',
                },
                {
                  src: sqlserver_logo,
                  title: 'SQLserver',
                },
                {
                  src: oracle_logo,
                  title: 'Oracle',
                },
                {
                  src: redis_logo,
                  title: 'Redis',
                },
                {
                  src: elasticsearch_logo,
                  title: 'Elasticsearch',
                },
                {
                  src: clickhouse_logo,
                  title: 'ClickHouse',
                }
              ]
          },
        ]
      }
    },
    {
      rootProps: { name: 'cloud', className: 'row', id: 'cloud' },
      textLeadImage: tile3,
      text: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: isEn ? 'Cloud and Infrastructure' : 'Cloud e Infraestrutura',
        },
        description: {
          text:
            isEn ? "Our team advocates the use of cloud computing for ease of access and a smoother update process across the board. In addition, we employ Continuous Integration and Continuous Delivery methods, mitigating the risk of an outdated code base and potential conflicts, as well as delivering software updates in shorter cycles. " : "A nossa equipa defende o uso de tecnologias em cloud para um acesso mais fácil e um processo de atualização uniformizado. Para além disso, utilizamos métodos de Continuous Integration e Continous Delivery, mitigando o risco de uma base de código desatualizada e potenciais conflitos, assim como a entrega de atualizações de software em ciclos mais curtos.",
        },
      },
      technologies: {
        rootProps: { className: 'col-6 col-12-medium' },
        items: [
          {
            // toolType: ['IDK'],
            logos:
              [
                {
                  src: aws_logo,
                  title: 'AWS',
                }, {
                  src: azure_logo,
                  title: 'Microsoft Azure',
                }, {
                  src: google_cloud_logo,
                  title: 'Google Cloud Platforming',
                }, {
                  src: gitlab_logo,
                  title: 'GitLab CI',
                }, {
                  src: jenkins_logo,
                  title: 'Jenkins',
                }, {
                  src: github_logo,
                  title: 'GitHub CI',
                }, {
					src: docker_logo,
					title: 'Docker',
				}, {
					src: azure_pipelines_logo,
					title: 'Microsoft Azure Pipelines',
				}, {
					src: kubernetes_logo,
					title: 'Kubernetes',
				}, 
              ]
          },
        ]
      }
    },
    {
      rootProps: { name: 'message-brokers', className: 'row', id: 'message-brokers' },
      textLeadImage: tile3,
      text: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: isEn ? 'Message Brokers' : 'Message Brokers',
        },
        description: {
          text:
            isEn ? "Different needs require different platforms, and when intercommunication is a must, we facilitate it via message brokers, preserving data integrity on both ends through reliable, tried and true systems." : "Cada plataforma traz consigo diferentes necessidades, e quando a intercomunicação é indispensável, facilitamo-la com message brokers, preservando a integridade dos dados em ambas as extremidades através de sistemas fidedignos e comprovados.",
        },
      },
      technologies: {
        rootProps: { className: 'col-6 col-12-medium' },
        items: [
          {
            // toolType: ['IDK'],
            logos:
              [
                {
                  src: kafka_logo,
                  title: 'Apache Kafka',
				}, {
					src: rabbitmq_logo,
					title: 'RabbitMQ',
				}, {
					src: aws_logo,
					title: 'AWS SQL/SNS',
				}
              ]
          },
        ]
      }
    },
  ];


  return (
    <section id="toolbox-two" className="container content">
      <div className="tools">
        {_.map(toolBlocks, (tool, i) => (
          <div {...tool.rootProps}>
            <TextGroup {...tool.text}>
              < div className="image">
                <img src={tool.textLeadImage} alt="" />
              </div>
            </TextGroup>
            <div {...tool.technologies.rootProps}>
              {_.map(tool.technologies.items, (item, i) => (
                <div className={'toolbox-group'} key={`toolbox-${i}`}>
                  <WorkTypes items={item.toolType} />
                  <div className='logos'>
                    {_.map(item.logos, (logo, i) => (
					  <div className='logo'>
						<img src={logo.src} title={logo.title} alt={logo.title} width="150px" height="100px" loading="lazy">
						</img>
						{logo.title}
					  </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section >
  )
}
export default ToolboxTwo
